import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56')
];

export const server_loads = [0];

export const dictionary = {
		"/app": [13,[2]],
		"/app/accountGroups/[id]": [15,[2,4]],
		"/app/accountGroups/[id]/actors": [16,[2,4]],
		"/app/accountGroups/[id]/contracts": [17,[2,4]],
		"/app/accountGroups/[id]/interlocuteurs": [18,[2,4]],
		"/app/accounts/[id=integer]": [19,[2,5]],
		"/app/accounts/[id=integer]/actors": [20,[2,5]],
		"/app/accounts/[id=integer]/contracts": [21,[2,5]],
		"/app/accounts/[id=integer]/interlocuteurs": [22,[2,5]],
		"/app/competency": [23,[2]],
		"/app/competency/per-technician": [24,[2]],
		"/app/contrats/[id=integer]": [25,[2,6]],
		"/app/contrats/[id=integer]/factures": [26,[2,6]],
		"/app/contrats/[id=integer]/interlocuteurs": [27,[2,6]],
		"/app/contrats/[id=integer]/interventions": [28,[2,6]],
		"/app/contrats/[id=integer]/periodes": [29,[2,6]],
		"/app/dashboard": [30,[2,7]],
		"/app/dashboard/astreinte": [31,[2,7]],
		"/app/dashboard/enregistrement": [32,[2,7]],
		"/app/dashboard/installBase": [33,[2,7]],
		"/app/dashboard/interventions": [34,[2,7]],
		"/app/dashboard/quote": [35,[2,7]],
		"/app/dashboard/regions": [36,[2,7]],
		"/app/interventionReport/[id]": [37,[2]],
		"/app/interventions": [38,[2]],
		"/app/invoices": [39,[2]],
		"/app/jyc": [40,[2]],
		"/app/notifs": [41,[2,8]],
		"/app/notifs/deltas": [42,[2,8]],
		"/app/projectsMonPlanning": [44,[2]],
		"/app/projects": [43,[2]],
		"/app/quotes/[id]": [45,[2,9]],
		"/app/score": [46,[2]],
		"/app/sites/[id=integer]": [47,[2,10]],
		"/app/sites/[id=integer]/acteurs": [48,[2,10]],
		"/app/sites/[id=integer]/contracts": [49,[2,10]],
		"/app/sites/[id=integer]/installBase": [50,[2,10]],
		"/app/sites/[id=integer]/projects": [51,[2,10]],
		"/app/sites/[id=integer]/quotes": [52,[2,10]],
		"/app/[[tab]]/[crud]": [14,[2,3]],
		"/auth/login": [53,[11]],
		"/design-system": [54,[12]],
		"/design-system/remult": [55,[12]],
		"/design-system/sql-optimizor": [56,[12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';